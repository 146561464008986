import { SectionBlocks } from "./types";

export const truncateText = (text: string, length: number): string => {
  return text.slice(0, length);
};

// Limits the text length to a certain number of lines
export const truncatePortableText = (
  blocks: SectionBlocks[] | null,
  length: number,
): SectionBlocks[] => {
  return blocks?.slice(0, length) ?? [];
};
