import classNames from "classnames";
import { Themes } from "./types";

type ThemeConfig = {
  interactive?: boolean;
  border?: boolean;
};

export const getThemeClassName = (
  theme?: Themes | null,
  config?: ThemeConfig,
) =>
  classNames({
    "bg-body text-body-contrast": !theme || theme == "body",
    "transition-colors hover:bg-body-contrast hover:text-body focus:[box-shadow:var(--cvr-focus)]":
      config?.interactive && (!theme || theme == "body"),
    "bg-elevated text-elevated-contrast": theme == "elevated",
    "transition-colors hover:bg-elevated-contrast hover:text-elevated focus:[box-shadow:var(--cvr-focus)]":
      config?.interactive && theme == "elevated",
    "bg-panel text-panel-contrast": theme == "panel",
    "transition-colors hover:bg-panel-contrast hover:text-panel focus:[box-shadow:var(--cvr-focus)]":
      config?.interactive && theme == "panel",
    "bg-primary text-primary-contrast": theme == "primary",
    "transition-colors hover:bg-primary-contrast hover:text-primary focus:[box-shadow:var(--cvr-focus)]":
      config?.interactive && theme == "primary",
    "shadow-[0_0_0_2px_var(--bg-primary)]":
      config?.border && theme == "primary",
    "bg-secondary text-secondary-contrast": theme == "secondary",
    "transition-colors hover:bg-secondary-contrast hover:text-secondary focus:[box-shadow:var(--cvr-focus)]":
      config?.interactive && theme == "secondary",
    "shadow-[0_0_0_2px_var(--bg-secondary)]":
      config?.border && theme == "secondary",
    "bg-tertiary text-tertiary-contrast": theme == "tertiary",
    "transition-colors hover:bg-tertiary-contrast hover:text-tertiary focus:[box-shadow:var(--cvr-focus)]":
      config?.interactive && theme == "tertiary",
    "shadow-[0_0_0_2px_var(--bg-tertiary)]":
      config?.border && theme == "tertiary",
  });
